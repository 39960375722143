<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="mb-8" v-if="schedule">
      <ScheduleInfo :schedule="schedule" />
    </div>

    <ReviewForm :answer="answer" v-if="answer" />
  </main>
</template>

<script>
import Answer from '../../../../../store/models/Answer';
import Schedule from '../../../../../store/models/Schedule';
import Task from '../../../../../store/models/Task';

import ScheduleInfo from '../../../../../components/student/schedule/ScheduleInfo.vue';
import ReviewForm from '../../../../../components/teacher/task/answer/ReviewForm.vue';

export default {
  components: { ScheduleInfo, ReviewForm },

  computed: {
    schedule() {
      return Schedule.query()
        .withAllRecursive()
        .find(this.$route.params.schedule_id);
    },

    task() {
      return Task.query().withAll().find(this.$route.params.task_id);
    },

    answer() {
      return Answer.query()
        .withAllRecursive()
        .find(this.$route.params.answer_id);
    },
  },

  metaInfo() {
    return { title: 'Review' };
  },

  async beforeMount() {
    const params = this.$route.params;

    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: params.schedule_id },
      });
    }

    if (!this.task) {
      await this.$store.dispatch('tasks/maybeFetch', {
        filter: { id: params.task_id },
      });
    }

    this.$store.dispatch('answers/fetch', {
      filter: { id: params.answer_id },
    });

    this.$store.commit('info/setBreadcrumb', [
      { path: '/guru/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
      { path: this.schedule.tasksRoutePath, label: 'Tugas' },
      {
        path: `/guru/jadwal/${params.schedule_id}/tugas/${params.task_id}/ubah`,
        label: this.task.title,
      },
      {
        path: `/guru/jadwal/${params.schedule_id}/tugas/${params.task_id}/jawaban`,
        label: 'Jawaban',
      },
    ]);
  },
};
</script>
