<template>
  <form class="bg-white rounded-8" @submit.prevent="handleSubmit">
    <div class="d-flex align-items-center justify px-24 py-16 border-bottom">
      <h2 class="fs-20 me-auto mb-0">
        {{ answer.student.user.name }}
        <small class="text-muted"> / {{ answer.student.nisn }} </small>
      </h2>
      <button
        type="submit"
        class="btn btn-primary"
        :class="{ 'is-loading': isSubmitting }"
        :disabled="isSubmitting"
      >
        Simpan
      </button>
    </div>

    <MarkdownContent
      class="p-24 border-bottom"
      :content="answer.text"
      v-if="answer.text"
    />

    <div class="p-24">
      <InputField
        label="Nilai"
        required
        v-model="input.score"
        :attrs="{ type: 'number', min: 0, max: 100 }"
        :error="errors.score"
      />

      <TextareaField
        class="mt-16"
        label="Komentar"
        v-model="input.comment"
        :error="errors.comment"
      />
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import answerRepository from '../../../../api/repositories/answerRepository';
import Answer from '../../../../store/models/Answer';

import MarkdownContent from '../../../content/MarkdownContent.vue';
import InputField from '../../../form/InputField.vue';
import TextareaField from '../../../form/TextareaField.vue';

export default {
  components: { MarkdownContent, InputField, TextareaField },

  props: ['answer'],

  data() {
    return { errors: {}, input: this.getInitialInput(), isSubmitting: false };
  },

  methods: {
    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;

        await answerRepository
          .review(this.answer.id, this.input)
          .then(({ data }) => Answer.insert({ data: [data.data] }));
        this.$alert.success('Jawaban berhasil direview');
      } catch (error) {
        this.errors = get(error, 'response.data.error.errors', {});
        this.$alert.requestError(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    getInitialInput() {
      const input = this.answer ? this.answer.$toJson() : {};
      return input;
    },
  },

  watch: {
    answer() {
      this.input = this.getInitialInput();
    },
  },
};
</script>
